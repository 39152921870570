<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn elevation="9" rounded color="grey" @click="$router.go(-1)">
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">
                        Shipments for order #{{ $route.params.id }}
                      </h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-data-table
                      :headers="headers"
                      :items="vendor_order_shipments"
                      sort-by="name"
                      class="elevation-1"
                    >
                      <template #top>
                        <v-toolbar flat>
                          <v-toolbar-title>Shipments</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template #item="{ item }">
                        <tr>
                          <td>
                            {{ item.id }}
                          </td>
                          <td>
                            {{ item.order_id }}
                          </td>
                          <td>
                            {{ item.created_at | date_format }}
                          </td>
                          <td>
                            {{ item.supplier_name }}
                          </td>
                          <!-- <td>
                              {{item.user_name}}
                            </td> -->
                          <!-- <td>
                              <span v-if="item.status != null"> {{item.status}} </span>
                              <span v-else> Pending </span>
                            </td> -->
                          <td>
                            <v-icon
                              small
                              class="mr-2"
                              @click="
                                $router.push('/store/ship-detail/' + item.id)
                              "
                            >
                              mdi-eye
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <!--end: Datatable-->
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    headers: [
      { text: "Shipment ID" },
      { text: "Order ID" },
      { text: "Date" },
      { text: "Supplier Name" },
      // { text: 'User' },
      // { text: 'Status' },
      { text: "Actions", sortable: false },
    ],
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // vendor_order_shipments: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderShipments($vendor_order_id: Int) {
    //       vendor_order_shipments(vendor_order_id: $vendor_order_id) {
    //         id
    //         order_id
    //         created_at
    //         status
    //         total
    //         supplier_name
    //         seller_name
    //         seller_user_name
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       vendor_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.$apollo.queries.vendor_order_shipments.refetch();
  },
  methods: {
    subTotal(items) {
      let sum = 0;
      if (items.length > 0) {
        for (let i in items) {
          sum += items[i].qty_to_return * items[i].price;
        }
      }
      return sum;
    },
    isInt(n) {
      return n % 1 === 0;
    },
  },
};
</script>
